//@author travis
import { DataAccess } from "../dataaccess/data.access";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { UserprefService } from "./userpref.service";
import { Cache } from "../decorators/cache.decorator";

export class TooltipService {
    private static TooltipService_Repository = {
        isSeized: false,
    };

    @Cache({ expireTimeMs: 120000, targetName: "TooltipService" })
    public static getTooltip(itemTypeId: number, itemId: number, itemId2: number) {
        let type = S25Const.itemType[itemTypeId].type;
        let itemId2Param = S25Util.isUndefined(itemId2) ? "" : S25Const.item_type_map[type].item2 + itemId2;
        return DataAccess.get(
            DataAccess.injectCaller(
                "/tooltip/" + type + ".json?itemId=" + itemId + "&" + type + "_id=" + itemId + itemId2Param,
                "TooltipService.getTooltip",
            ),
        );
    }

    public static getDisable() {
        return UserprefService.getDisableTooltip();
    }

    public static isSeized() {
        return TooltipService.TooltipService_Repository.isSeized;
    }

    public static setSeized() {
        TooltipService.TooltipService_Repository.isSeized = true;
    }

    public static setFree() {
        TooltipService.TooltipService_Repository.isSeized = false;
    }
}
